<template>
  <div>
    <b-tabs
      pills
    >
      <b-tab
        active
        lazy
      >
        <template #title>
          <feather-icon icon="BarChart2Icon" />
          <span>Finansman Maliyeti</span>
        </template>
        <b-card>
          <financial-cost-card />
        </b-card>
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import {
  BTab, BTabs, BCard,
} from 'bootstrap-vue'
import FinancialCostCard from '@/views/Reports/Sales/ManagerGPR/financialCosts.vue'

export default {
  name: 'ManagerGPR',
  components: {
    FinancialCostCard,
    BTabs,
    BTab,
    BCard,
  },
}
</script>
