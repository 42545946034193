<template>
  <div>
    <b-table-simple
      hover
      caption-top
      caption-bottom
      responsive
      bordered
      small
    >
      <b-thead>
        <b-tr>
          <b-th
            class="font-small-2 text-nowrap  width-100 align-middle"
          >
            Marka
          </b-th>
          <b-th class="font-small-2 text-nowrap align-middle width-100 text-center">
            Model
          </b-th>
          <b-th class="font-small-2 text-nowrap align-middle width-100 text-center">
            Şase
          </b-th>
          <b-th class="font-small-2 text-nowrap align-middle width-100 text-center">
            Alış Tarihi
          </b-th>
          <b-th class="font-small-2 text-nowrap align-middle width-100 text-center">
            Vade Tarihi
          </b-th>
          <b-th class="font-small-2 text-nowrap align-middle width-100 text-center">
            Satış Tarihi
          </b-th>
          <b-th class="font-small-2 text-nowrap align-middle width-100 text-center">
            Alış Tutarı
          </b-th>
          <b-th class="font-small-2 text-nowrap align-middle width-100 text-center">
            Vade(Gün)
          </b-th>
          <b-th class="font-small-2 text-nowrap align-middle width-100 text-center">
            VALÖR
          </b-th>
          <b-th class="font-small-2 text-nowrap align-middle width-200 text-center">
            K(-)/Z(+)
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <template v-for="(item,key) in carData">
          <b-tr
            :key="key"
          >
            <b-td class="font-small-2 text-center">
              {{ item.brand }}
            </b-td>
            <b-td class="font-small-2 text-center">
              {{ item.model }}
            </b-td>
            <b-td
              class="font-small-2 text-center font-weight-bold"
            >
              {{ item.chasis }}
            </b-td>
            <b-td class="font-small-2 text-center">
              {{ item.purchase_inv_date ? moment(item.purchase_inv_date).format('DD.MM.YYYY') : '-' }}
            </b-td>
            <b-td class="font-small-2 text-center">
              {{ moment(item.EXPIRY_DATE).format('DD.MM.YYYY') }}
            </b-td>
            <b-td class="font-small-2 text-center">
              {{ item.inv_date ? moment(item.inv_date).format('DD.MM.YYYY') : '-' }}
            </b-td>
            <b-td class="font-small-2 text-center">
              {{ item.purchase_price | toCurrency }} TL
            </b-td>
            <b-td class="font-small-2 text-center">
              {{ item.diff }} Gün
            </b-td>
            <b-td
              class="font-small-2 text-center"
              :class="item.sale_diff > 0 ? 'text-danger' : 'text-success'"
            >
              {{ item.sale_diff }} Gün
              <div>
                <small class="text-secondary font-small-1"><b>Oran</b><br>{{ item.rate }}</small>
              </div>
            </b-td>
            <b-td class="font-small-2 text-center">
              {{ item.result_value }} TL
              <div>
                <small
                  :class="Number(item.result) > 0 ? 'text-danger text-bold' : 'text-success'"
                >{{ item.result_text }} TL</small>
              </div>
              <div>
                <small class="text-warning">{{ item.sale_diff }} Gün</small>
              </div>
            </b-td>
          </b-tr>
        </template>
      </b-tbody>
      <b-tfoot>
        <b-tr v-if="costVisible">
          <b-th
            colspan="9"
            class="font-small-2"
          >
            Toplam Finansman Geliri
          </b-th>
          <b-th class="font-small-2 text-center">
            {{ totalsData.profits.toLocaleString('tr-TR', { minimumFractionDigits: 2 }) }} TL
          </b-th>
        </b-tr>
        <b-tr>
          <b-th
            colspan="9"
            class="font-small-2"
          >
            Toplam Finansman Gideri
          </b-th>
          <b-th class="font-small-2 text-center">
            {{ totalsData.losts.toLocaleString('tr-TR', { minimumFractionDigits: 2 }) }} TL
          </b-th>
        </b-tr>
      </b-tfoot>
    </b-table-simple>
  </div>
</template>
<script>
import {
  BTableSimple, BTbody, BTd, BTfoot, BTh, BThead, BTr,
} from 'bootstrap-vue'

export default {
  name: 'CarListTable',
  components: {
    BTableSimple,
    BTd,
    BTfoot,
    BTbody,
    BTh,
    BThead,
    BTr,
  },
  props: {
    carData: {
      type: Array,
      required: true,
    },
    periodData: {
      type: Array,
      required: true,
    },
    totalsData: {
      type: Object,
      required: true,
    },
    costVisible: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
