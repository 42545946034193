<template>
  <div>
    <financial-cost-filter
      :get-filtered-data="setFinancialCosts"
    />
    <loading v-if="loadingReport" />
    <template v-else>
      <manager-stock-g-p-r-total-card
        v-if="getFinancialCosts.totals"
        :grand-total="getFinancialCosts.totals"
      />
      <template v-for="(brand,key) in getFinancialCosts.brands">
        <div
          v-if="brand.data.length >0"
          :key="key"
        >
          <div class="font-weight-bold font-medium-2 my-2 bg-light-primary p-1 rounded">
            {{ brand.title }}
          </div>
          <stock-manager-g-p-r-statistics
            :statistics-data="brand.statistics"
            class="mb-2"
          />
          <car-list-table
            :totals-data="brand.totals"
            :period-data="getFinancialCosts.periods"
            :car-data="brand.data"
            :cost-visible="false"
          />
        </div>
      </template>
    </template>
  </div>
</template>
<script>
import FinancialCostFilter from '@/views/Reports/Sales/ManagerGPR/FinancialCosts/Components/StockFilterCard.vue'
import Loading from '@/views/Reports/Sales/ManagerGPR/FinancialCosts/Components/LoadingReport.vue'
import CarListTable from '@/views/Reports/Sales/ManagerGPR/FinancialCosts/Components/carList.vue'
import StockManagerGPRStatistics from '@/views/Reports/Sales/ManagerGPR/FinancialCosts/Components/Statistics.vue'
import ManagerStockGPRTotalCard from '@/views/Reports/Sales/ManagerGPR/FinancialCosts/Components/TotalCard.vue'

export default {
  name: 'StockFinancialCostCard',
  components: {
    ManagerStockGPRTotalCard,
    StockManagerGPRStatistics,
    CarListTable,
    Loading,
    FinancialCostFilter,
  },
  computed: {
    loadingReport() {
      return this.$store.getters['salesManagerGpr/getStockFinancialLoading']
    },
    getFinancialCosts() {
      return this.$store.getters['salesManagerGpr/getStockFinancialCosts']
    },
    getFinancialFilter() {
      return this.$store.getters['salesManagerGpr/getStockFinancialFilterParams']
    },
  },
  created() {
    this.setFinancialCosts()
  },
  methods: {
    setFinancialCosts() {
      this.getFinancialFilter.reportType = 'stocks'
      this.$store.dispatch('salesManagerGpr/stockFinancialCosts', this.getFinancialFilter)
    },
  },
}
</script>
