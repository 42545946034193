<template>
  <b-row>
    <b-col>
      <item-statistics
        color="light-primary"
        :value="statisticsData.cars"
        title="Araç"
        icon="InfoIcon"
      />
    </b-col>
    <b-col>
      <item-statistics
        color="light-success"
        :value="statisticsData.days_1_30"
        title="1-30 Gün"
        icon="CheckCircleIcon"
      />
    </b-col>
    <b-col>
      <item-statistics
        color="light-warning"
        :value="statisticsData.days_31_60"
        title="31-60 Gün"
        icon="InfoIcon"
      />
    </b-col>
    <b-col>
      <item-statistics
        color="light-danger"
        :value="statisticsData.days_61"
        title="61+ Gün"
        icon="XCircleIcon"
      />
    </b-col>
    <b-col>
      <item-statistics
        color="light-info"
        :value="Number(statisticsData.value_data)"
        title="Valör"
        icon="BarChartIcon"
      />
    </b-col>
  </b-row>
</template>
<script>
import { BRow, BCol } from 'bootstrap-vue'
import ItemStatistics from '@/views/Reports/Sales/ManagerGPR/FinancialCosts/Components/ItemStatistics.vue'

export default {
  name: 'StockManagerGPRStatistics',
  components: {
    BRow,
    BCol,
    ItemStatistics,
  },
  props: {
    statisticsData: {
      type: Object,
      required: true,
    },
  },
}
</script>
